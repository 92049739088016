import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { DatabaseService } from '../_services/DatabaseService';

@Component({
  selector: 'app-system-user',
  templateUrl: './system-user.component.html',
  styleUrls: ['./system-user.component.scss']
})
export class SystemUserComponent implements OnInit {

  search: any = {};
  userForm:any = {};
  filter: any={};
  productlist: any;
  loading_list=false;
  toggle:any;
  userlist = [];
  data:any=[];
  savingData = false;
  constructor(public db:DatabaseService,public dialog:DialogComponent,private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.getUserList();
  }

  getUserList(){
    
    this.db.post_rqst({'filter':this.filter},"master/systemUser").subscribe(r=>{
      
      this.userlist=r['user']['data'];
      console.log(this.userlist);
      this.loading_list=false;
      
    });
  }
  addUser(){
    console.log("add user click");
    this.userForm={};
    this.loading_list=false;
    this.getUserList();
  }

  numeric_Number(event: any) {
    console.log("mobile number is :"+event.target.value);
    
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
    }
  }

  updateStatus(i,event){
    this.db.post_rqst({'checked' : event.checked, 'id' : this.userlist[i].id,'login_id':this.db.datauser.id}, 
    'master/userStatus').subscribe(d => 
      {
        this.dialog.success( 'Status Change successfully ');
        this.getUserList();
      });
  }
  saveUser(form:any){
    console.log(this.userForm);
    this.db.post_rqst({"user":this.userForm},'master/addUser').subscribe(r=>{
      console.log(r);
    
      if(r.status == 'EXIST' || r.status == 'Phone EXIST' )
      {
        this.dialog.error( ' User  with this mobile no. Already exists');
            return;
      }
      else{
        this.dialog.success( 'User successfully added');
      }

    this.userForm = {};
    this.toggle = false ;
  
    this.getUserList();

    })
  }

  editUser(val)
 
  {
      this.userForm = val;
      console.log(this.userForm);
      this.loading_list=true;
      this.getUserList();
      
  }
  deleteUser(id) {
   this.dialog.delete('User').then((result) => {
       if(result) {
           this.db.post_rqst({id:id}, 'master/userDelete')
           .subscribe(d => {
               console.log(d);
               this.getUserList();
               this.dialog.successfully();
           });
       }
   });
 }
}
