import { Component, OnInit } from '@angular/core';
import { MatDatepicker, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '../dialog/dialog.component';
import { MastetDateFilterModelComponent } from '../mastet-date-filter-model/mastet-date-filter-model.component';
import { DatabaseService } from '../_services/DatabaseService';
import { SessionStorage } from '../_services/SessionService';

@Component({
  selector: 'app-coupon-upload',
  templateUrl: './coupon-upload.component.html',
  styleUrls: ['./coupon-upload.component.scss']
})
export class CouponUploadComponent implements OnInit {

  loading_list = false;
  coupon: any = {};
  available_coupon: any = {};
  history: any = {};
  last_page: number ;
  current_page = 1;
  product_code;
  product_size:any=[];
  savingData = false;
  filter:any = {};
  date1;
  product_data:any =[]

  constructor(public db: DatabaseService, private route: ActivatedRoute, private router: Router, 
    public ses: SessionStorage,
    public alrt: MatDialog,  public dialog: DialogComponent) { }

  ngOnInit() {
    this.getProduct('');
    this.getAvailableCoupanList('');
  }
  getProduct(event){
    this.db.post_rqst({'search':event},'app_master/coupon_product').subscribe(r=>{
      console.log(r);
      this.product_data=r['products'];
      console.log(this.product_data);
    })
  }
  getAvailableCoupanList(f){
    console.log("coupon list is come");
    this.db.post_rqst('','app_master/coupon_history').subscribe(r=>{
      // console.log(r);
      this.available_coupon=r['coupon'];
      console.log(this.available_coupon);
    })
  }
  // selectProductSize(e){
    // console.log("onchange works: "+e);
    // this.db.post_rqst({'product_code':e},'app_master/coupon_product_size').subscribe(r=>{
    //   // console.log(r);
    //   this.product_size=r['productSize'];
    //   console.log(this.product_size);
    // })
  // }

  saveCouponfrom(form: any) {
    console.log(this.coupon);
    
    if (this.coupon.total_coupon <= 2500) {
      this.loading_list = true;
      this.savingData = true;
      this.coupon.created_by = this.db.datauser.id;
      console.log(this.coupon);
      this.db.post_rqst({ 'coupon': this.coupon }, 'app_master/generate_coupon')
        .subscribe(d => {
          if (d['status'] == 'Fields Reqired') {
            this.dialog.error('Fields Reqired!');
            return;
          }
          console.log(this.coupon);
          form.resetForm();
          this.dialog.success('Coupon has been successfully Generated');
          this.savingData = false;
          this.loading_list = false;
          // this.getCouponDetail();
          this.getAvailableCoupanList('');
          // this.router.navigate(['/coupon-code-list']);
        });
    }
    else {
      this.dialog.error('Can not Generate more than 2500 coupon codes at once!');
    }
  }

  current()
  {
      this.current_page = 1;
      this.getAvailableCoupanList('');
  }
  redirect_previous() {
    this.current_page--;
    this.getAvailableCoupanList('');
  }
  redirect_next() {
      if (this.current_page < this.last_page) 
      { 
        this.current_page++; 
      }
      else 
      { 
        this.current_page = 1; 
      }
      this.getAvailableCoupanList('');
  }
  last()
  {
      this.current_page = this.last_page;
      this.getAvailableCoupanList('');
  }
  numeric_Number(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  openDatePicker(picker : MatDatepicker<Date>)
    {
        picker.open();
    }
  openDatepicker(): void {
    const dialogRef = this.alrt.open(MastetDateFilterModelComponent, {
        width: '500px',
        data: {
            from:this.filter.date_from,
            to:this.filter.date_to
        }
    });
    
    dialogRef.afterClosed()
    .subscribe(result => {
        this.filter.date_from = result.from;
        this.filter.date_to = result.to;
        this.getAvailableCoupanList('');
    });
  }
  downloadCoupon(id){
    this.db.post_rqst({'id':id}, 'app_master/exportCoupon')
    .subscribe( d => {
        document.location.href = this.db.myurl+'/app/uploads/exports/coupons.csv';
        console.log("downloaded Excel sucessfully");
    });
  }

  deleteCoupon(id) {
    this.dialog.delete('Coupon').then((result) => {
    if(result) {
        this.db.post_rqst({'id': id}, 'app_master/delete_multi_coupon').subscribe(d => {
        console.log(d);
        this.getAvailableCoupanList('');
        this.dialog.successfully();
        });
        }
    });
  } 
}
